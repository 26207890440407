.ui.accordion .active.title .icon:not(.button),
.ui.accordion .accordion .active.title .icon:not(.button) {
  &.question.tiny.icon {
    transform: None !important;
  }
  &.caret.down.icon {
    transform: None !important;
  }
  color: @carrotOrange;
}

.ui.accordion .title:not(.ui) {
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  font-size: 1.28571429rem;
  padding: .5em 0;
}

.ui.accordion .accordion {
  padding: 0 0 0 2em;
}
